.fixed-header {
  background-color: #ffffff;
}
.header-nav li a.active {
  color: #218b03;
  font-weight: 600;
}
.main-logo {
  width: 125px;
  height: 42px;
}
.nav-icon {
  width: 16px;
  height: 16px;
  margin-left: 2px;
}
.header-nav li a {
  color: black;
}
.dark-button {
  background-color: #141414;
  border-radius: 144px;
  display: inline-block;
}
.dark-button a.nav-link {
  color: #ffffff;
  text-align: center;
}

.primary-button {
  background-color: #218B03;
  border-radius: 144px;
  display: inline-block;
  width: fit-content;
}
.primary-button a.nav-link {
  color: #ffffff;
  text-align: center;
}

.secondary-button {
  background-color: #fbc429;
  border-radius: 144px;
  display: inline-block;
  width: fit-content;
}
.secondary-button a.nav-link {
  color: #000;
  text-align: center;
  font-weight: bold;
}
.values-title {
  font-size: 2rem;
  font-weight: 600;
}
.stats-box {
  background-color: #f1fff2;
}
.stats-title {
  font-size: 2rem;
  font-weight: 800;
}
.stats-number {
  font-size: 3rem;
  font-weight: 700;
  background: linear-gradient(126.23deg, #0e971b 0%, #a59f00 91.51%);
  background: -webkit-linear-gradient(126.23deg, #0e971b 0%, #a59f00 91.51%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.stats-number-text {
  font-size: 1.125rem;
  font-weight: 700;
}
.fancy-box {
  padding: 2px 5px 2px 5px;
  background-color: #1f8702;
  color: #fff;
  rotate: -2.33deg;
  font-size: 2rem;
  font-weight: 600;
}
.testimonial-box-light {
background-color: #EDF6EB;
}

.testimonial-box-title {
  color: #1f8702;
  font-size: 2rem;
  font-weight: 600;
}
.outline-button {
  background-color: transparent;
  border-radius: 144px;
  display: inline-block;
  border: 1px solid black;
}
.outline-button a.nav-link {
  color: #000;
  text-align: center;
}
.hero-bg {
  background-image: url("../public/assets/img/background.png");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  animation: mymove 20s infinite;
}
@keyframes mymove {
  25% {
    background-position: top left;
  }
  50% {
    background-position: top right;
  }
  75% {
    background-position: top left;
  }
  100% {
    background-position: top right;
  }
}
.hero-section {
  margin-top: 112px;
  margin-bottom: 112px;
}

.service-hero-section {
  max-height: 400px;
}

.slide-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.hero-subtitle {
  font-size: 1.375rem;
}
.hero-subtitle-bold {
  font-size: 2rem;
  font-weight: bolder;
}
.slide h1 {
  font-size: 3.25rem;
  color: #4caf4f;
}
.slide-container span {
  font-size: 3.25rem;
}
.cta-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.partner-section {
  background-color: #FFF;
  padding: 18px;
}

.partner-text {
  font-size: 2.5rem;
  font-weight: 600;
}
.partner-logo {
  width: 100%;
}

.icon {
  width: 40px;
  height: 40px;
}

.service-box {
  padding: 2rem 16px 2rem 16px;
}
.stats-section {
  background-color: #f5f7fa;
  padding-left: 144px;
  padding-right: 144px;
  padding-top: 84px;
  padding-bottom: 84px;
}

.green-title-text {
  font-size: 36px;
  line-height: 44px;
  font-weight: 400;
  color: #4caf4f;
}

.black-title-text {
  font-size: 36px;
  line-height: 44px;
  font-weight: 400;
  color: black;
}

.stats-icon {
  width: 48px;
  height: 48px;
}

.premium-finance-service {
  background-color: #f7ffd9;
  margin-bottom: 40px;
}

.service-image {
  max-width: 400px;
  max-height: 511px;
}

.policy-assignment-service {
  background-color: #d9eaff;
  margin-bottom: 40px;
}

.loan-service {
  background-color: #ffddd9;
  margin-bottom: 40px;
}

.card-radius {
  border-radius: 2rem;
}

.learn-more-button {
  background-color: #4caf4f;
  border-radius: 4;
  display: inline-block;
  border-radius: 4px;
  padding: 14px 2rem 14px 2rem;
}

.learn-more-button-text {
  color: #ffffff;
  text-align: center;
}

.white-text {
  color: #ffffff;
}

.policy-card {
  width: 100%;
  margin-top: 20px;
}

.why-choose-us-section {
  background-image: url("../public/assets/img/sin-wave.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}
.family-banner {
  background-image: url("../public/assets/img/family-banner.png");
  height: 100%;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
}
.story-box {
  background-color: white;
}
.choose-us-box {
  background-color: white;
  border-radius: 12px;
}

.even {
  position: relative;
  top: -80px;
}

.contact-us-section {
  background-color: #FFF;
}

.footer-section {
  background-color: #218b03;
  padding: 64px 120px 64px 120px;
}

.footer-section h3 {
  margin-bottom: 24px;
}

.white-logo {
  width: 100px;
  margin-bottom: 24px;
}

.menu-icon {
  width: 37px;
  height: 37px;
}
.hero-image {
  width: 100%;
}
.benefit-icon {
  width: 48px;
  height: 48px;
}

.eligibility-check-section {
  background-color: #f2f2f2;
}
.aboutus-container {
  padding-left: 400px;
  padding-right: 400px;
  padding-top: 50px;
  padding-bottom: 50px;
}
.value-box {
  background-color: #1f2933;
}

.customer-centrix-box {
  background-color: #f7ffd9;
  border-radius: 32px;
  padding: 36px;
  margin-bottom: 24px;
}

.innovation-box {
  background-color: #ffddd9;
  border-radius: 32px;
  padding: 36px;
  margin-bottom: 24px;
}
.transparency-box {
  background-color: #d9eaff;
  border-radius: 32px;
  padding: 36px;
  margin-bottom: 24px;
}
.commitment-box {
  background-color: #f7ffd9;
}
.commitment-image {
  width: 100%;
}
.switch {
  width: 100%;
}
.team-box {
  border-radius: 18px;
  background-color: #f8fef6;
}
.team-image {
  width: 100%;
  border-radius: 10px 10px 0px 0px;
}
.team-detail-box {
  background-color: #f8fef6;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 0px 0px 10px 10px;
}
.linkedin-icon {
  width: 32px;
  height: 32px;
  margin-bottom: 8px;
}
.service-details-section li {
  font-size: 20px;
  line-height: 48px;
  text-align: justify;
}
a.white-text {
  text-decoration: none;
}
a.white-text:hover {
  color: #fff;
}
.blue-text {
  color: #2673f0;
  text-transform: uppercase;
  letter-spacing: 3px;
}
.eligibility-title {
  font-size: 40px;
}
.eligibility-description {
  font-size: 20px;
}
.social-icon {
  width: 32px;
  height: 32px;
}
.carousel-slide {
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.slide-for p {
  font-size: 1.5rem;
  font-weight: 700;
  color: #1f8702;
  text-align: justify;
}
.slide-title p {
  font-size: 2.25rem;
  font-weight: 700;
  color: #000;
  text-align: left;
}
.slide-subtitle p {
  font-size: 1.5rem;
  font-weight: 500;
  color: #6b6b6b;
  font-style: italic;
  text-align: justify;
}

.banner-title p {
  font-size: 3rem;
  font-weight: 700;
  color: #000;
  text-align: left;
}
.banner-subtitle p {
  font-size: 1.125rem;
  font-weight: 400;
  color: #6b6b6b;
  font-style: italic;
  text-align: justify;
}

.slider-img {
  width: 100%;
}
.primaryBox {
  background-color: #1f8702;
}
.primaryBox p {
  color: #fff;
}
.lightBox {
  background-color: #fff;
  display: flex;
}
.lightBox p {
  color: #000;
}
.chevron {
  width: 1.5rem;
  margin-right: 8px;
}
.service-title {
  font-size: 2.25rem;
  font-weight: 700;
  text-align: left;
}
.white {
  color: #fff;
}
.service-subtitle {
  font-size: 1rem;
  font-weight: 400;
  text-align: left;
  font-style: italic;
}
.service-points {
  font-size: 1.15rem;
  font-weight: 400;
  text-align: left;
  font-style: italic;
}
.banner-controls {
  background-color: #d8f4da;
  padding: 16px 24px 16px 24px;
}
.control-pill {
  background-color: #fff;
  border-radius: 100px;
  padding: 8px 20px 8px 20px;
  margin-left: 8px;
  margin-left: 12px;
  margin-right: 12px;
  cursor: pointer;
}
.active-pill {
  background-color: #218b03;
}
.active-pill span {
  color: white;
}
.why-briezi-icon {
  width: 40px;
}
.why-briezi-icon-caption {
  font-size: 1.125rem;
  font-weight: 400;
  font-style: italic;
  margin-top: 8px;
}
.why-briezi-title {
  font-size: 2.7rem;
  font-weight: 500;
}
.why-briezi-subtitle {
  font-size: 1.125rem;
  font-weight: 500;
}
.video-player {
  border-radius: 20px;
}
.chevron-green {
  width: 22.5px;
  height: 22.5px;
}
.values-points {
  width: 80%;
  border: 0.4px solid #1f8702;
  border-radius: 100px;
  padding: 20px;
}
.values-points-subtitle {
  font-size: 0.875rem;
  font-style: italic;
  font-weight: 400;
}
.value-title {
  background-color: #1f8702;
  width: fit-content;
  position: relative;
  padding: 4px;
  padding-left: 12px;
  padding-right: 12px;
  border-radius: 100px;
  left: 24px;
  top: 20px;
}
.value-title span {
  color: white;
  font-size: 12px;
}
.values-container {
  min-height: 100px;
}
.valueFor {
  font-weight: 600;
}
.testimonial {
  border-width: 1px solid #badede;
  border-radius: 18px;
}
.testimonial-text {
  font-size: 0.8rem;
  font-weight: 400;
  font-style: italic;
}
.testimonial-img {
  width: 88px;
  height: 88px;
  border-radius: 44px;
}
.testimonial-by {
  font-size: 1rem;
  font-weight: 600;
}
.testimonial-star {
  height: 18px;
}
.testimonial-quote-img {
  height: 32px;
}
.testimonial {
  gap: 0px;
  border-radius: 18px;
  border: 1px 0px 0px 0px;
  border: 1px solid #BADEDE;
  background: #FBFCFD;

}
.faq-title {
  font-size: 26px;
  font-weight: 700;
}
.faq-box {
  background-color: #FBFCFD;
}
.faq-box .card { 
  background-color: #FFFFFF;
}
.contactus-title {
  font-size: 4rem;
  font-weight: 600;
}
.contactus-subtitle {
  font-size: 1.25rem;
  
}
.footer-text {
  font-size: 0.875rem;
}
.footer-title-text {
  font-size: 1.25rem;
}
.breadcrumb-nav, .breadcrumb {
  background-color: #FFF;
}
.breadcrumb-item a, .breadcrumb-item a:hover {
  font-size: 1rem;
  font-weight: 400;
  color: #000;
  text-decoration: none;
}
.breadcrumb-nav .active a {
  color: #0e971b;
}
.whatIs-box {
  background-color: #F1FFF2;
}
.solution-box {
  background-color: #FFF;
}
.whatIs-title {
  font-size: 2rem;
  font-weight: 700;
}
.whatIs-description {
  font-size: 1.125rem;
  font-weight: 400;
  font-style: italic;
}
.solution-item {

border-radius: 20px;
background-color: #EDF6EB;

}
.solution-icon {
  width: 56px;
  height: 56px;
}
.solution-title {
  font-size: 1.25rem;
  font-weight: 600;
  color: #1F8702;
}
.solution-description {
  font-size: 1rem;
  font-weight: 400;
  color: #191919;
  font-style: italic;
}
.ulip-box {
  background-color: #EDF6EB;
  border-radius: 160px 0px 0px 0px;
  
}
.team-img {
  width: 250px;
  height: 250px;
  position: relative;
  bottom: 70px;
  border-radius: 30px;
}
.teams-box {
  background-color: #1F8702;
  
}
.team-detail-box {
  background-color: #FFF;
  border-radius: 16px;
}
.image-container {
  background-color: #F1FFF2;
  border-radius: 16px;
}
.team-name {
  font-size: 1rem;
  font-weight: 600;
  color: #1F8702;
}
.designation {
  font-size: 0.75rem;
  font-weight: 300;
  color: #141414;
}
.profile {
  font-size: 0.875rem;
  font-weight: 400;
  color: #191919;
  font-style: italic;
}
.meet {
  font-size: 1.125rem;
  font-style: italic;
  color: #FFF;
}
.founders-text {
  font-size: 2rem;
  font-weight: 700;
  color: #FFF;
}
.contact-box {
  background-color: #F5F7FA;
  padding: 32px;
}
.contact-title {
  font-size: 4rem;
  font-weight: 600;
  color: #141414;
}
.contact-subtitle {
  font-size: 1.25rem;
  font-weight: 400;
  color: #141414;
}
.form-box {
  background-color: #FFF;
  border: 1px solid #4CAF4F;
  border-radius: 20px;
}
.contactus-title {
  font-size: 2rem;
  font-weight: 600;
  color: #141414;
}
.call-us {
  font-size: 1rem;
  font-weight: 400;
  color: #141414;
}
.phone {
  font-size: 1.5rem;
  font-weight: 600;
  color: #141414;
}
.callcenter-img {
  width: 76%;
}
.boldTitle {
  font-weight: 700;
}
.px-80 {
  padding-left: 80px;
  padding-right: 80px;
}
@media (max-width: 575.98px) {
  .fixed-header {
    padding-left: 18px;
    padding-right: 18px;
    padding-top: 18px;
    padding-bottom: 18px;
    background-color: #ffffff;
  }
  .hero-bg {
    background-image: url("../public/assets/img/background.png");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    animation: mymove 20s infinite;
  }
  .navbar-light .navbar-toggler {
    border: 0px;
  }
  .hero-subtitle-bold {
    font-size: 1.5rem;
    font-weight: bolder;
  }
  .hero-subtitle {
    font-size: 1rem;
  }
  .partner-section {
    background-color: #FFF;
    padding: 8px;
  }
  .partner-logo {
    max-width: 100%;
  }
  .service-box {
    margin-bottom: 24px;
  }
  .stats-section {
    background-color: #f5f7fa;
    padding-left: 24px;
    padding-right: 24px;
    padding-top: 84px;
    padding-bottom: 84px;
    text-align: center;
  }
  .service-image {
    max-width: 300px;
    max-height: 511px;
  }
  .even {
    position: relative;
    top: 0px;
  }
  .footer-section {
    background-color: #4caf4f;
    padding: 16px 30px 16px 30px;
  }
  .aboutus-container {
    padding-left: 8px;
    padding-right: 8px;
  }
  .loan-service {
    background-color: #ffddd9;
    margin-bottom: 40px;
    padding: 10px;
  }
  ul {
    padding: 0px;
  }
  .card-radius {
    border-radius: 0px;
  }
  .hero-section {
    margin-top: 42px;
    margin-bottom: 42px;
  }
  .team-img {
    width: 150px;
    height: 150px;
    border-radius: 30px;
    bottom: 0px;
  }
  .why-briezi-icon-caption {
    font-size: 0.9rem;
    font-weight: 400;
    font-style: italic;
    margin-top: 8px;
  }
}

@media (min-width: 576px) {
  .even {
    position: relative;
    top: 0px;
  }
  .premium-finance-service,
  .policy-assignment-service,
  .loan-service {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .aboutus-container {
    padding-left: 8px;
    padding-right: 8px;
  }
  .hero-section {
    margin-top: 42px;
    margin-bottom: 42px;
  }
}

@media (min-width: 992px) {
  .px-lg-80{
    padding-left: 80px !important;
    padding-right: 80px !important;
  }
}